import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
  defaultColumn: {
    width: "100px"
  },
  defaultNumberColumn: {
    paddingRight: "30px",
    textAlign: "right",
    width: "100px"
  },
  allColumns: {
    paddingRight: theme.marginScale.medium,
    paddingLeft: theme.marginScale.medium
  },
  unitNotesImage: {
    width: "25px",
    height: "25px"
  },
  unitNotesColumn: {
    width: "60px"
  },
  unitNotesColumnBody: {},
  propertyColumn: {
    width: "180px"
  },
  unitColumn: {
    width: "65px"
  },
  rpmColumn: {
    width: "125px"
  },
  bedroomColumn: {
    width: "55px"
  },
  affordabilityTypeColumn: {
    width: "100px"
  },
  excludedColumn: {
    width: "50px"
  },
  excludedReasonColumn: {
    width: "125px"
  },
  ltlGtlColumn: {
    width: "80px"
  },
  adjLtlGtlColumn: {
    width: "95px"
  },
  netEffectiveRent: {
    width: "110px"
  },
  rightAlignColumn: {
    width: "90px",
    textAlign: "right"
  },
  residentDisplayColumn: {
    width: "125px"
  },
  residentCodeColumn: {
    width: "50px"
  },
  termCategoryColumn: {
    width: "100px"
  },
  rentDisplayValue: {
    width: "125px"
  },
  growthRateColumnRightAlign: {
    width: "75px"
  },
  adjustedRentColumn: {
    width: "75px"
  },
  parameterAppliedColumn: {
    width: "115px"
  },
  bestOfferColumn: {
    width: "90px"
  },
  concessionOverrideDollarColumnRightAlign: {
    width: "100px"
  },
  bestOfferOverrideDollarColumnRightAlign: {
    width: "90px"
  },
  bestOfferOverridePercentColumnRightAlign: {
    width: "90px"
  },
  rentOneYearAgoColumn: {
    width: "85px"
  },
  rentChange: {
    width: "85px"
  },
  upfrontConcessionsColumn: {
    width: "92px"
  },
  recurringConcessionsColumn: {
    width: "85px"
  },
  newConcessionsColumn: {
    width: "110px"
  },
  inputField: {
    display: "flex",
    justifyContent: "center"
  },
  filterHeader: {
    display: "flex",
    alignItems: "flex-start"
  },
  filterHeaderText: {
    paddingRight: "5px"
  },
  effectiveDateOverrideColumn: {
    width: "110px"
  },
  scalingColumn: {
    width: "60px"
  },
  leaseDescriptionColumn: {
    width: "115px"
  },
  maxByLawColumn: {
    width: "75px"
  }
}));
